import '../../App.css';
import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import { auth } from '../../firebase';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import { db } from '../../firebase-config';

function Admin() {
    const [user, setUser] = useState(null);
    const [items, setItems] = useState([]);
    const [orders, setOrders] = useState([]);
    const [events, setEvents] = useState([]);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUpdateCreate, setIsUpdateCreate] = useState(null);
    const [updateData, setUpdateData] = useState({ product: '', price: '', cost: '', time: '', id: '' });

    useEffect(() => {
        const fetchProductsData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "products"));
                const itemsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setItems(itemsList);

                const querySnapshot1 = await getDocs(collection(db, "orders"));
                const itemsList1 = querySnapshot1.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setOrders(itemsList1);

                const querySnapshot2 = await getDocs(collection(db, "events"));
                const itemsList2 = querySnapshot2.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setEvents(itemsList2);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchProductsData();

        const unsubscribe = auth.onAuthStateChanged(setUser);
        return () => unsubscribe();
    }, []);

    const handleOpenModal = () => {
        setIsModalOpen(true);
        setIsUpdateCreate("Create");
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleUpdate = async (item) => {
        setIsModalOpen(true);
        setIsUpdateCreate("Update");
        setUpdateData({ product: item.name, price: item.price, cost: item.cost, time: item.time, id: item.id });
    };

    const handleDelete = async (item) => {
        setIsModalOpen(true);
        setIsUpdateCreate("Delete");
        setUpdateData({ product: item.name, price: item.price, cost: item.cost, time: item.time, id: item.id });
    };

    const getOrdinalSuffix = (day) => {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    const convertTimestamp = (timestamp) => {
        const date = new Date(timestamp * 1000); 
        const day = date.getUTCDate(); 
        const month = date.toLocaleString('en-US', { month: 'long' });
        const year = date.getUTCFullYear();

        const suffix = getOrdinalSuffix(day);

        return `${month} ${day}${suffix} ${year}`;
    };

    return (
        <div className="admin">
            {user ? (<div>
                <h1>Welcome Kayleigh</h1>

                <button className="important">Suspend Orders</button>

                <h2>Orders</h2>
                <div className="admin_table">
                    <div className="admin_table_heading">
                        <div className="order admin_table_row_container">
                            <p>Date</p>
                            <p>Name</p>
                            <p>Item</p>
                            <p>Price</p>
                            <p>Status</p>
                            <p>Actions</p>
                        </div>
                    </div>

                    {orders.map(order => (
                        <div key={order.id} className="admin_table_row_container">
                            <p>{convertTimestamp(order.date)}</p>
                            <p>{order.name}</p>
                            <p></p>
                            <p>${order.price}</p>
                            <p>{order.status}</p>
                            <div>
                                <button onClick={() => handleUpdate(order)}>Edit</button>
                                <button onClick={() => handleDelete(order)}>Delete</button>
                            </div>
                        </div>
                    ))}
                </div>

                <h2>Products</h2>
                <div>
                    <div className="admin_table_heading">
                        <div className="admin_table_row_container">
                            <p>Image</p>
                            <p>Name</p>
                            <p>Description</p>
                            <p>Price</p>
                            <p>Time</p>
                            <p>Actions</p>
                        </div>
                    </div>

                    {items.map(item => (
                        <div key={item.id} className="admin_table_row_container">
                            <img src={item.img} alt="" />
                            <p>{item.name}</p>
                            <p>{item.description}</p>
                            <p>${parseFloat(item.price).toFixed(2)}</p>
                            <p>{item.time} hours</p>
                            <div>
                                <button onClick={() => handleUpdate(item)}>Edit</button>
                                <button onClick={() => handleDelete(item)}>Delete</button>
                            </div>
                        </div>
                    ))}

                </div>
                <button onClick={handleOpenModal} className="important">Add a Product</button>


                <h2>Events</h2>
                <div className="admin_table">
                    <div className="admin_table_heading">
                        <div className="admin_table_row_container">
                            <p>Date</p>
                            <p>Name</p>
                        </div>
                    </div>

                    {events.map(event => (
                        <div key={event.id} className="admin_table_row_container">
                            <p>{convertTimestamp(event.date.seconds)}</p>
                            <p>{event.name}</p>
                        </div>
                    ))}
                </div>

                <button>Add an Event</button>

                <h2>Blog Posts</h2>
                <button>Add a Blog Post</button>

                <Modal isOpen={isModalOpen} onClose={handleCloseModal} formLocation={isUpdateCreate} documentData={updateData} />
                </div>
            ) : (
                <div><p>You are not logged in.</p></div>
            )}
        </div>
    )
}

export default Admin;