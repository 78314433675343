import React, { useState } from 'react';
import '../App.css';
import { db } from '../firebase-config';
import { collection, addDoc } from 'firebase/firestore';
import { MdOutlineEmail } from "react-icons/md";
import { LuInstagram } from "react-icons/lu";
import { FaFacebookF } from "react-icons/fa";

const Contact = () => {
    const [formData, setFormData] = useState(
        { fname: '', lname: '', email: '', phone: '', description: '', budget: '' }
    );
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        setError('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { name, email, phone, message } = formData;

        if (!name || !email || !phone || !message) {
            setError('Please fill out all required fields');
            return;
        }

        try {
            await addDoc(collection(db, 'messages'), {
                name,
                email,
                phone,
                message,
                createdAt: new Date()
            });

            setSuccess(true);
            setFormData({ fname: '', lname: '', email: '', phone: '', description: '', budget: '' });
        } catch (error) {
            console.error('Error adding document: ', error);
            setError('Failed to submit the order. Please try again later.');
        }
    };

    return (
        <div className="custom-message-form">
            <h2 className="custom-message-form-title">Contact Me</h2>
            <div className="custom-order-button-container">
                <button><MdOutlineEmail /></button>
                <button><LuInstagram /></button>
                <button><FaFacebookF /></button>
            </div>
            {success ? (
                <p className="custom-order-submitted">Thank you for submitting an order! I will get back to you as soon as I can. </p>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className="custom-order-formblock">
                        <label htmlFor="name"> Name:</label><br />
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="custom-order-formblock">
                        <label htmlFor="phone">Phone:</label><br />
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="custom-order-formblock">
                        <label htmlFor="email">Email:</label><br />
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="custom-order-formblock">
                        <label htmlFor="message">Message:</label><br />
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            rows="4"
                            cols="50"
                        />
                    </div>

                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <div className="custom-orders-submit-btn">
                        <button type="submit" className="important">Submit</button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default Contact;