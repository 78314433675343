import '../App.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { GrMailOption } from "react-icons/gr";
import { GrDocumentPdf } from "react-icons/gr";
import { GrGift } from "react-icons/gr";

function About() {

  return (
    <div className="about">
      <div className="home_banner">
        <div className="home_banner_text_container">
          <h1 className="home_banner_text curved-text">Hookers Welcome!</h1>
          <p>not your grandmother's crochet... <br /> but if she's fun she'd approve</p>
          <button className="important"><Link to="/store/items" className="link">My Store<IoArrowForwardCircleOutline /></Link></button>
        </div>
      </div>

      <div className="home_section section1">
        <div className="home_section1_text1_container">
          <h2>Find the crochet project for YOU</h2>
          <p>Small functional items, blankets, stuffed animals and more. There's something for every hooker.</p>
        </div>
        <div className="home_section1_buttons_container">
          <button className="important"><Link to="/store/patterns" className="link">Patterns <GrDocumentPdf /></Link></button>
          <button className="important"><Link to="/store/items" className="link">Finished Items <GrGift /></Link></button>
        </div>
      </div>

      <div className="home_section section2">
        <div className="home_section2_text">
          <h2>Hey there, I'm Kayleigh</h2>
          <p>
            I started crocheting a few years ago, and I was <span>hooked</span> right away :). 
            Over the years, I've had the joy of teaching classes, sharing the love of crochet with beginners, and going to crochet clubs. 
            It’s amazing to see how something as simple as yarn can connect people in such meaningful ways. 
            I was even fortunate enough to be interviewed for a local newspaper article!
            Whenever I can, I enjoy selling my handmade pieces at craft fairs, and it always warms my heart to see people appreciate the time and effort that goes into each creation.
            <br /><br />
            When I'm not crocheting, I'm a software developer, and I actually coded this entire website myself using React! 
            It’s a blend of creativity and logic that I really enjoy, much like crochet. Both allow me to build and design, just with different tools. 
            Whether I’m creating a new pattern or building a feature for a website, I’m always learning and growing, and I wouldn’t have it any other way.
          </p>

          <button className="important"><Link to="/contact" className="link">Contact Me <GrMailOption /></Link></button>

        </div>

        <div className="home_section2_image">
          <img src={`${process.env.PUBLIC_URL}/1.png`} alt="" />
        </div>
      </div>
    </div>
  )
}

export default About;