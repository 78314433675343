import React, { useState } from 'react';
import '../../App.css';
import { db } from '../../firebase-config';
import { collection, addDoc } from 'firebase/firestore';

const CustomOrder = () => {
    const [formData, setFormData] = useState(
        { fname: '', lname: '', email: '', phone: '', description: '', budget: '' }
    );
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value 
        });
        setError('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const { fname, lname, email, phone, description, budget } = formData;
    
        if (!fname || !lname || !email || !phone || !description || !budget) {
            setError('Please fill out all required fields');
            return;
        }
    
        try {
            await addDoc(collection(db, 'orders'), {
                fname,
                lname,
                email,
                phone,
                description,
                budget,
                createdAt: new Date()
            });
    
            setSuccess(true);
            setFormData({ fname: '', lname: '', email: '', phone: '', description: '', budget: '' });
        } catch (error) {
            console.error('Error adding document: ', error);
            setError('Failed to submit the order. Please try again later.');
        }
    };

    return (
        <div className="custom-order-form">
            <h2>Custom Order</h2>
            <p>I would love to make a custom order for you. Please enter the specifics below, and I will get back to you with a quote and timeline.</p>
            {success ? (
                <p className="custom-order-submitted">Thank you for submitting an order! I will get back to you as soon as I can. </p>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className="custom-order-formblock-collection">
                        <div className="custom-order-formblock">
                            <label htmlFor="fname">First Name:</label><br />
                            <input
                                type="text"
                                id="fname"
                                name="fname"
                                value={formData.fname}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="custom-order-formblock">
                            <label htmlFor="lname">Last Name:</label><br />
                            <input
                                type="text"
                                id="lname"
                                name="lname"
                                value={formData.lname}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="custom-order-formblock">
                        <label htmlFor="phone">Phone:</label><br />
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="custom-order-formblock">
                        <label htmlFor="email">Email:</label><br />
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="custom-order-formblock">
                        <label htmlFor="budget">Budget:</label><br />
                        <input
                            type="text"
                            id="budget"
                            name="budget"
                            value={formData.budget}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="custom-order-formblock">
                        <label htmlFor="description">Please Describe Your Order:</label><br />
                        <textarea
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            rows="4"
                            cols="50"
                        />
                    </div>

                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <div className="custom-orders-submit-btn">
                        <button type="submit" className="important">Submit</button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default CustomOrder;
