import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase-config';
import '../../CSS/ItemDetail.css';

function ItemDetail() {
    const { id } = useParams();
    const [item, setItem] = useState(null);
    const [itemId, setItemId] = useState(null);
    const [error, setError] = useState(null);
    const [isInCart, setIsInCart] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false); // To track image load state

    const fetchItem = async () => {
        try {
            const docRef = doc(db, 'products', id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setItem(docSnap.data());
                setItemId(docSnap.id);
                setImageLoaded(true)
            } else {
                const docRef = doc(db, 'patterns', id);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setItem(docSnap.data());
                    setItemId(docSnap.id);
                } else {
                    setError("Item not found");
                }
            }
        } catch (error) {
            setError(error.message);
        }
    };

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    };

    const checkCart = () => {
        const existingCart = getCookie('cart');
        const cartArray = existingCart ? JSON.parse(decodeURIComponent(existingCart)) : [];
        setIsInCart(cartArray.includes(id));
    };

    useEffect(() => {
        const initialize = async () => {
            await fetchItem();
            checkCart();
        };

        initialize();
    }, [id]);

    const handleAddToCart = (id) => {
        const setCookie = (name, value, days) => {
            const expires = new Date(Date.now() + days * 864e5).toUTCString();
            document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/`;
        };

        const existingCart = document.cookie
            .split('; ')
            .find((row) => row.startsWith('cart='))
            ?.split('=')[1];
        let cartArray = existingCart ? JSON.parse(decodeURIComponent(existingCart)) : [];

        if (!cartArray.includes(id)) {
            cartArray.push(id);
        }

        setCookie('cart', JSON.stringify(cartArray), 7);
        setIsInCart(true);
    };

    if (error) return <p>Error: {error}</p>;
    if (!item) return <p>Loading...</p>;

    return (
        <div className="item-detail-container">
            <img 
                src={imageLoaded ? item.img : '../../assets/images/placeholder500.png'} 
                alt={item.name || "Loading image"} 
                loading="lazy" 
                onLoad={() => setImageLoaded(true)} 
                width="500"
                height="500"
            />
            <div className="item-detail-container-details">
                <h3>${Number(item.price).toFixed(2)}</h3>

                <div className="item-detail-price-banner">
                    <p>New Item!</p>
                </div>

                <p>{item.name}, {item.description}</p>
                <p>Time:</p>
                <p>Pattern Used:</p>
                <p><strong>Request now, pay later</strong></p>

                {!isInCart && (
                    <button className="important add-to-cart" onClick={() => handleAddToCart(itemId)}>Add to Cart</button>
                )}
                {isInCart && <p className="item-detail-warning">This item has been added to your cart</p>}
            </div>
        </div>
    );
}

export default ItemDetail;
