import '../src/App.css';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { MdOutlineShoppingCart } from "react-icons/md";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaRegHeart } from "react-icons/fa";

function Header() {
    const [cartItems, setCartItems] = useState(0);
    const [favoriteItems, setFavoriteItems] = useState(0);

    const getCartCount = () => {
        const cartCookie = document.cookie
            .split('; ')
            .find(row => row.startsWith('cart='))
            ?.split('=')[1];
        const cartArray = cartCookie ? JSON.parse(decodeURIComponent(cartCookie)) : [];
        return cartArray.length;
    };

    useEffect(() => {
        const checkCookieChange = () => {
            const cartCookie = document.cookie
                .split('; ')
                .find(row => row.startsWith('cart='))
                ?.split('=')[1];
            const cartArray = cartCookie ? JSON.parse(decodeURIComponent(cartCookie)) : [];

            if (cartArray.length !== cartItems.length) {
                setCartItems(cartArray.length);
            }
        };

        const intervalId = setInterval(checkCookieChange, 1000);
        return () => clearInterval(intervalId);
    }, [cartItems]);

    useEffect(() => {
        const checkCookieChange = () => {
            const favoriteCookie = document.cookie
                .split('; ')
                .find(row => row.startsWith('favorite='))
                ?.split('=')[1];
            const favoriteArray = favoriteCookie ? JSON.parse(decodeURIComponent(favoriteCookie)) : [];

            if (favoriteArray.length !== favoriteItems.length) {
                setFavoriteItems(favoriteArray.length);
            }
        };

        const intervalId = setInterval(checkCookieChange, 1000);
        return () => clearInterval(intervalId);
    }, [favoriteItems]);


    useEffect(() => {
        setCartItems(getCartCount());
    }, []);

    return (
        <header className="header">
            <div className="header_nav_menu_container">
                <button><Link to="/store/items">store</Link></button>
                <button><Link to="/events">events</Link></button>
                <button><Link to="/blog">blog</Link></button>
            </div>

            <div className="header_logo_container">
                <Link to="/"><img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" className="header_logo" /></Link>
            </div>

            <button className="store-main-menu-btn"><a href="/cart"><MdOutlineShoppingCart /></a></button>

            <div className="header_contact_menu_container">
                <button><Link to="/contact"><MdOutlineMailOutline /></Link></button>
                <button className="shopping-cart-btn">
                    <Link to="/favorites">
                        <FaRegHeart />
                        {favoriteItems > 0 && <p className="header-menu-number-icon">{favoriteItems}</p>}
                    </Link>
                </button>

                <button className="shopping-cart-btn">
                    <Link to="/cart">
                        <MdOutlineShoppingCart />
                        {cartItems > 0 && <p className="header-menu-number-icon">{cartItems}</p>}
                    </Link>
                </button>
            </div>
        </header>
    )
}

export default Header;
