import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { useNavigate } from 'react-router-dom';

function Patterns() {
    const [items, setItems] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "patterns"));
                const itemsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setItems(itemsList);
            } catch (error) {
                setError(error.message);
            } 
        };

        fetchData();
    }, []);

    if (error) return <p>Error: {error}</p>;

    const handleClick = (id) => {
        navigate(`/store/patterns/${id}`);
    };

    return (
        <div>
            <div className="item-container">
                {items.map(item => (
                    <li key={item.id} className="single-item-container" onClick={() => handleClick(item.id)}>
                        <img src={item.img} alt="" className="singe-item-img" />
                        <h2>{item.name}</h2>
                        <p>{item.description}</p>
                        <p className="single-item-price">${Number(item.price).toFixed(2)}</p>
                    </li>
                ))}
            </div>
        </div>
    );
}

export default Patterns;
