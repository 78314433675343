import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase-config'; 
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { MdEventAvailable } from "react-icons/md";



function Events() {

    const [items, setItems] = useState([]);
    const [upcomingItems, setUpcomingItems] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "events"));
                const itemsList = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                const currentTime = Math.floor(Date.now() / 1000); 
                const upcoming = [];
                const past = [];

                itemsList.forEach(item => {
                    if (item.date.seconds > currentTime) {
                        upcoming.push(item);
                    } else {
                        past.push(item);
                    }
                });

                const sortedUpcoming = upcoming.sort((a, b) => b.date.seconds - a.date.seconds);
                const sortedPast = past.sort((a, b) => b.date.seconds - a.date.seconds);

                setUpcomingItems(sortedUpcoming); 
                setItems(sortedPast);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchData();
    }, []);
    

    const getOrdinalSuffix = (day) => {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    const convertTimestamp = (timestamp) => {
        const date = new Date(timestamp * 1000); 
        const day = date.getUTCDate(); 
        const month = date.toLocaleString('en-US', { month: 'long' });
        const year = date.getUTCFullYear();

        const suffix = getOrdinalSuffix(day);

        return `${month} ${day}${suffix} ${year}`;
    };

    return (
        <div className="events">
            <h1>Events</h1>

            <div className="all-events-container">
                <div className="event-container">
                    <h2>Upcoming Events</h2>
                    {upcomingItems.map(item => (
                        <li key={item.id} className="single-event-container" >
                            <p><MdEventAvailable /> {item.name}</p>
                            <p>{convertTimestamp(item.date.seconds)}</p>
                        </li>
                    ))}
                </div>

                <div className="event-container">
                    <h2>Past Events</h2>
                    {items.map(item => (
                        <li key={item.id} className="single-event-container" >
                            <p><MdEventAvailable /> {item.name}</p>
                            <p>{convertTimestamp(item.date.seconds)}</p>
                        </li>
                    ))}
                </div>
            </div>
        </div>
        
    )
}

export default Events;