import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { useNavigate } from 'react-router-dom';
import { GoHeartFill } from "react-icons/go";
import { GoHeart } from "react-icons/go";

function Items() {
    const [items, setItems] = useState([]);
    const [error, setError] = useState(null);
    const [cartItems, setCartItems] = useState([]);
    const [favoriteItems, setFavoriteItems] = useState([]);
    const [hoveredId, setHoveredId] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "products"));
                const itemsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setItems(itemsList);
            } catch (error) {
                setError(error.message);
            }
        };
        fetchData();
        const favoriteArray = checkFavorite();
        setFavoriteItems(favoriteArray);
    }, []);

    if (error) return <p>Error: {error}</p>;

    const handleClick = (id) => {
        navigate(`/store/items/${id}`);
    };

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    };

    const checkCart = (id) => {
        const existingCart = getCookie('cart');
        const cartArray = existingCart ? JSON.parse(decodeURIComponent(existingCart)) : [];
        return cartArray.includes(id);
    };

    const checkFavorite = () => {
        const existingFavorite = getCookie('favorite');
        const favoriteArray = existingFavorite ? JSON.parse(decodeURIComponent(existingFavorite)) : [];
        return favoriteArray;
    };

    const handleAddToCart = (id) => {
        const setCookie = (name, value, days) => {
            const expires = new Date(Date.now() + days * 864e5).toUTCString();
            document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/`;
        };

        const existingCart = getCookie('cart');
        let cartArray = existingCart ? JSON.parse(decodeURIComponent(existingCart)) : [];

        if (!cartArray.includes(id)) {
            cartArray.push(id);

            setCookie('cart', JSON.stringify(cartArray), 7);
            setCartItems(cartArray);
        }
    };

    const handleAddToFavorite = (id) => {
        const setCookie = (name, value, days) => {
            const expires = new Date(Date.now() + days * 864e5).toUTCString();
            document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/`;
        }

        const existingFavorite = getCookie('favorite');
        let favoriteArray = existingFavorite ? JSON.parse(decodeURIComponent(existingFavorite)) : [];

        if (!favoriteArray.includes(id)) {
            favoriteArray.push(id);

            setCookie('favorite', JSON.stringify(favoriteArray), 7);
            setFavoriteItems(favoriteArray);
        } else {
            favoriteArray = favoriteArray.filter(itemId => itemId !== id);
            setCookie('favorite', JSON.stringify(favoriteArray), 7); 
            setFavoriteItems(favoriteArray);
        }
    };

    return (
        <div>
            <div className="item-container">
                {items.map(item => (
                    <li key={item.id} className="single-item-container" onClick={() => handleClick(item.id)}>
                        <button className="item-favorite-heart-button"
                            onMouseEnter={() => setHoveredId(item.id)}
                            onMouseLeave={() => setHoveredId(null)} 
                            onClick={(e) => {e.stopPropagation(); handleAddToFavorite(item.id)}}>
                            {hoveredId === item.id || favoriteItems.includes(item.id) ? <GoHeartFill /> : <GoHeart />}
                        </button>
                        <img src={item.img} alt="" className="singe-item-img" loading="lazy" />
                        <h2>{item.name}</h2>
                        <p>{item.description}</p>
                        <div className="single-item-bottom-container">
                            <p className="single-item-price">${Number(item.price).toFixed(2)}</p>
                            {checkCart(item.id) ? (
                                <button className="important" disabled>
                                    Already in Cart
                                </button>
                            ) : (
                                <button
                                    className="important"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleAddToCart(item.id);
                                    }}>
                                    Add to Cart
                                </button>
                            )}
                        </div>
                    </li>
                ))}
            </div>
        </div>
    );
}

export default Items;
