import React, { useState, useEffect } from 'react';
import { collection, getDoc, doc, addDoc } from 'firebase/firestore';
import { MdDeleteForever } from "react-icons/md";
import { db } from '../firebase-config';
import '../App.css';
import { IoClose } from "react-icons/io5";

function Cart() {
    const [cartItems, setCartItems] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [totalCost, setTotalCost] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showMessage, setShowMesssage] = useState(false);
    const [message, setMesssage] = useState(false);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [timeline, setTimeline] = useState('');
    const [payment, setPayment] = useState('');

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    };

    const fetchCartItems = async (itemIds) => {
        try {
            const fetchedItems = [];

            for (let id of itemIds) {
                const productRef = doc(db, 'products', id);
                const patternRef = doc(db, 'patterns', id);

                const productSnap = await getDoc(productRef);
                if (productSnap.exists()) {
                    fetchedItems.push({ id, ...productSnap.data(), collection: 'products' });
                    continue;
                }

                const patternSnap = await getDoc(patternRef);
                if (patternSnap.exists()) {
                    fetchedItems.push({ id, ...patternSnap.data(), collection: 'patterns' });
                    continue;
                }
            }

            let cost = 0;
            fetchedItems.forEach(item => {
                cost += Number(item.price);
            });

            setTotalCost(cost);
            setCartItems(fetchedItems);
        } catch (error) {
            setError(error.message);
        }
        setLoading(false);
    };

    const removeCartItems = () => {
        document.cookie = "cart=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        const cartCookie = getCookie('cart');
        const cartArray = cartCookie ? JSON.parse(decodeURIComponent(cartCookie)) : [];

        fetchCartItems(cartArray);
    };

    const setCookie = (name, value, days) => {
        const expires = new Date(Date.now() + days * 864e5).toUTCString();
        document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/`;
    };

    const removeCartItem = (id) => {
        // Get the current cart from the cookie
        const cartCookie = getCookie('cart');
        const cartArray = cartCookie ? JSON.parse(decodeURIComponent(cartCookie)) : [];

        // Filter out the item that is to be removed
        const updatedCartArray = cartArray.filter(itemId => itemId !== id);

        // Update the cookie with the new cart (without the removed item)
        setCookie('cart', JSON.stringify(updatedCartArray), 7);

        // Update the state with the new cart (without the removed item)
        setCartItems(cartItems.filter(item => item.id !== id));
    };

    const openCheckout = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            await addDoc(collection(db, 'orders'), {
                name: name,
                phone: phone,
                email: email,
                address: address,
                timeline: timeline,
                payment: payment,
                status: "new",
                items: cartItems.map(item => ({
                    id: item.id,
                    name: item.name,
                    price: item.price,
                    quantity: item.quantity || 1,
                    collection: item.collection, 
                }))
            });

            removeCartItems();
            setShowMesssage(true);
            setMesssage("Your order has been submitted");

        } catch (error) {
            console.error('Error adding document: ', error);
            setShowMesssage(true);
            setMesssage(`There was an error submitting your order: ${error}`);
        }
        
    };

    useEffect(() => {
        const cartCookie = getCookie('cart');
        const cartArray = cartCookie ? JSON.parse(decodeURIComponent(cartCookie)) : [];

        if (cartArray.length > 0) {
            fetchCartItems(cartArray);
        } else {
            setLoading(false);
        }
    }, []);

    if (loading) return <p>Loading cart items...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className="cart">
            <h1>Cart</h1>
            <div className="cart-details">
                {cartItems.length > 0 ? (
                    cartItems.map(item => (
                        <div key={item.id} className="cart-item">
                            <img src={item.img} alt={item.name} />
                            <h3>{item.name}</h3>
                            <p className="cart-item-collection">{item.collection === 'products' ? 'Physical Item' : 'Pattern'}</p>
                            <p className="cart-item-description">{item.description}</p>
                            <p>${item.price ? Number(item.price).toFixed(2) : 'N/A'}</p>
                            <div className="cart-item-buttons">
                                <button onClick={() => removeCartItem(item.id)}><MdDeleteForever /></button>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>Your cart is empty.</p>
                )}
                {/* <div className="cart-total-container">
                    <p>Total: <strong>${totalCost.toFixed(2)}</strong></p>
                </div> */}
            </div>

            {cartItems.length > 0 ? (
                <button className="important checkout-btn" onClick={openCheckout}>Checkout</button>
            ) : null}

            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <button className="close-btn important" onClick={closeModal}><IoClose /></button>
                        <h2>Checkout</h2>

                        {showMessage && <p className="order-submitted-warning">{message}</p>}

                        <div className="modal-cart-items">
                            {cartItems.length > 0 ? (
                                cartItems.map(item => (
                                    <div key={item.id} className="modal-cart-item">
                                        <p>{item.name}</p>
                                        <p>{item.collection === 'products' ? 'Physical Item' : 'Pattern'}</p>
                                        <p className="modal-cart-items-price">${item.price ? Number(item.price).toFixed(2) : 'N/A'}</p>
                                    </div>
                                ))
                            ) : (
                                <p>Your cart is empty.</p>
                            )}
                            {/* <div className="modal-cart-total">
                                <p>Total: ${totalCost.toFixed(2)}</p>
                            </div> */}
                        </div>

                        <div className="modal-cart-form">
                            <form onSubmit={handleFormSubmit}>
                                <div className="form-group">
                                    <label htmlFor="name">Name:</label><br />
                                    <input
                                        type="text"
                                        id="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email:</label><br />
                                    <input
                                        type="email"
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="phone">Phone:</label><br />
                                    <input
                                        type="tel"
                                        id="phone"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="address">Address:</label><br />
                                    <input
                                        type="text"
                                        id="address"
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="timeline">Timeline:</label><br />
                                    <input
                                        type="text"
                                        id="timeline"
                                        value={timeline}
                                        onChange={(e) => setTimeline(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="payment">Payment Type:</label><br />
                                    <input
                                        type="text"
                                        id="payment"
                                        value={payment}
                                        onChange={(e) => setPayment(e.target.value)}
                                        required
                                    />
                                </div>
                                <button type="submit" className="important cart-submit">Submit</button>
                            </form>
                        </div>

                    </div>
                </div>
            )}
        </div>
    );
}

export default Cart;