import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import Header from './Header';
import Footer from './Footer';
import About from './Components/About';
import Store from './Components/Store/Store';
import Events from './Components/Events';
import Blog from './Components/Blog';
import Admin from './Components/Admin/Admin';
import Contact from './Components/Contact';
import Login from './Components/Admin/Login';
import Cart from './Components/Cart';
import Favorite from './Components/Favorite';

function App() {

  return (
    <div className="App">
      <Router>
        <Header />
        <div className="content">
          <Routes>
            <Route path="/" element={<About />} />
            <Route path="/store/*" element={<Store />} />
            <Route path="/events" element={<Events />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/favorites" element={<Favorite />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;

