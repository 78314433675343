import React from 'react';
import { NavLink, Route, Routes } from 'react-router-dom';
import Items from './Items';
import ItemDetail from './ItemDetail';
import Patterns from './Patterns';
import CustomOrders from './CustomOrders';
import '../../App.css';

function Store() {
    return (
        <div className="store">
            <div className="store_tabs">
                <NavLink to="items" className="tab-link">Physical Items</NavLink>
                <NavLink to="patterns" className="tab-link">Patterns</NavLink>
                <NavLink to="custom" className="tab-link">Custom Orders</NavLink>
            </div>
        
            <div className="store_tab_content">
                <Routes>
                    <Route path="/" element={<div>Please select a tab.</div>} />
                    <Route path="items" element={<Items />} />
                    <Route path="items/:id" element={<ItemDetail />} />
                    <Route path="patterns" element={<Patterns />} />
                    <Route path="patterns/:id" element={<ItemDetail />} />
                    <Route path="custom" element={<div><CustomOrders /></div>} />
                </Routes>
            </div>
        </div>
    );
}

export default Store;
