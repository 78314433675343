import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase-config';
import { doc, getDoc } from 'firebase/firestore';
import { GoHeartFill } from "react-icons/go";
import { GoHeart } from "react-icons/go";
import '../App.css';

function Favorite() {
    const [items, setItems] = useState([]);
    const [favoriteItems, setFavoriteItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [cartItems, setCartItems] = useState([]);
    const [hoveredId, setHoveredId] = useState(null);

    const navigate = useNavigate();


    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    };

    const checkFavorite = () => {
        const existingFavorite = getCookie('favorite');
        const favoriteArray = existingFavorite ? JSON.parse(decodeURIComponent(existingFavorite)) : [];
        return favoriteArray;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const favoriteCookie = getCookie('favorite');
                const favoriteIds = favoriteCookie ? JSON.parse(decodeURIComponent(favoriteCookie)) : [];

                if (favoriteIds.length > 0) {
                    const fetchedItems = [];
                    for (let id of favoriteIds) {
                        const productRef = doc(db, 'products', id);
                        const patternRef = doc(db, 'patterns', id);

                        const productSnap = await getDoc(productRef);
                        if (productSnap.exists()) {
                            fetchedItems.push({ id, ...productSnap.data(), collection: 'products' });
                            continue;
                        }

                        const patternSnap = await getDoc(patternRef);
                        if (patternSnap.exists()) {
                            fetchedItems.push({ id, ...patternSnap.data(), collection: 'patterns' });
                            continue;
                        }
                    }
                    setItems(fetchedItems);
                } else {
                    setItems([]);
                }
            } catch (err) {
                setError('Error fetching favorite items');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
        fetchData();

        const favoriteArray = checkFavorite();
        setFavoriteItems(favoriteArray);
    }, []);

    if (loading) return <p>Loading favorite items...</p>;
    if (error) return <p>{error}</p>;

    const checkCart = (id) => {
        const existingCart = getCookie('cart');
        const cartArray = existingCart ? JSON.parse(decodeURIComponent(existingCart)) : [];
        return cartArray.includes(id);
    };

    const handleAddToCart = (id) => {
        const setCookie = (name, value, days) => {
            const expires = new Date(Date.now() + days * 864e5).toUTCString();
            document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/`;
        };

        const existingCart = getCookie('cart');
        let cartArray = existingCart ? JSON.parse(decodeURIComponent(existingCart)) : [];

        if (!cartArray.includes(id)) {
            cartArray.push(id);

            setCookie('cart', JSON.stringify(cartArray), 7);
            setCartItems(cartArray);
        }
    };

    const handleAddToFavorite = (id) => {
        const setCookie = (name, value, days) => {
            const expires = new Date(Date.now() + days * 864e5).toUTCString();
            document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/`;
        }

        const existingFavorite = getCookie('favorite');
        let favoriteArray = existingFavorite ? JSON.parse(decodeURIComponent(existingFavorite)) : [];

        favoriteArray = favoriteArray.filter(itemId => itemId !== id);
        setCookie('favorite', JSON.stringify(favoriteArray), 7);
        setFavoriteItems(favoriteArray);

        const divElement = document.getElementById(id);
        if (divElement) {
            divElement.remove();
        }

    };

    const handleClick = (id) => {
        navigate(`/store/items/${id}`);
    };

    return (
        <div className="favorite">
            <h1>Favorites</h1>
            {items.length === 0 ? (
                <p>No favorite items found.</p>
            ) : (
                <div className="favorite-items">
                    <div className="favorite-container">
                        {items.map(item => (
                            <li key={item.id} id ={item.id} className="favorite-item" onClick={() => handleClick(item.id)}>
                                <button className="item-favorite-heart-button"
                                    onMouseEnter={() => setHoveredId(item.id)}
                                    onMouseLeave={() => setHoveredId(null)}
                                    onClick={(e) => { e.stopPropagation(); handleAddToFavorite(item.id) }}>
                                    {hoveredId === item.id || favoriteItems.includes(item.id) ? <GoHeartFill /> : <GoHeart />}
                                </button>
                                <img src={item.img} alt="" className="singe-item-img" loading="lazy" />
                                <h2>{item.name}</h2>
                                <p>{item.collection === 'products' ? 'Physical Item' : 'Pattern'}</p>
                                <p>{item.description}</p>

                                <div className="favorite-bottom-container">
                                    <p className="favorite-bottom-container-price">${item.price ? Number(item.price).toFixed(2) : 'N/A'}</p>
                                    {checkCart(item.id) ? (
                                        <button className="important" disabled>
                                            Already in Cart
                                        </button>
                                    ) : (
                                        <button
                                            className="important"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleAddToCart(item.id);
                                            }}>
                                            Add to Cart
                                        </button>
                                    )}
                                </div>
                            </li>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Favorite;
